import React, { useState, useEffect } from "react"
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput'
import { checkout } from "../utils/authorizeCard"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from "../components/Loading"

const TopUp = () => {
  console.log("IN TOP UP")
  
  const {
    error: authError,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(
      )
    } catch (error) {
      console.log("Authentication error: " + error)
      console.log("Redirecting to home page")

      // Authentication problem, log the user out and go back to home page
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  if (authError !== undefined) {
    console.log("Authentication error: " + authError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const [message, setMessage] = useState("")
  const [amount, setAmount] = useState(20)

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const amountStr = query.get("amount")
      const amount = parseInt(amountStr)

      if (isNaN(amount)) {
        setMessage("Successfully topped up your balance! The money will soon be available on your account.");
      } else {
        setMessage(`Successfully topped up your balance with ${amount/100}€! The money will soon be available on your account.`);
      }
    }

    if (query.get("canceled")) {
      setMessage(
        "Failed to top up your balance. Please try again."
      );
    }
  }, []);

  const initiateCheckout = async () => {
    const token = await getAccessToken()
    await checkout(amount, token)
  }

  return message ? (
    <Message message={message} />
  ) : (
    <>
      <NumberInput
        min={1}
        value={amount}
        onChange={(_event, amount) => setAmount(amount)}
      />
      <button onClick={initiateCheckout}>
        Checkout
      </button>
    </>
  )
}

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
)

export default withAuthenticationRequired(TopUp, {
  onRedirecting: () => <Loading />,
})