import React from "react"
import  { Redirect } from 'react-router-dom'
//import Button from '@mui/material/Button'
import Cards from "./Cards"
import { Button } from "@material-tailwind/react"
import { useAuth0 } from '@auth0/auth0-react'
import loading from "../assets/loading.svg"

// TODO: update Card.js, Home.js, Loading.js, App.js, package.json, tailwind.config.js, index.html
//       on RPi
//       also do npm install on RPi
// TODO: check if user is authenticated, if yes -> navigate to /cards directly
const Home = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  const login = () => loginWithRedirect()

  if (isAuthenticated) {
    return <Redirect to="/cards" />
  }

  return (
    <div className="h-full flex bg-gradient-to-t from-autorize-blue to-autorize-light-blue">
      <div className="m-auto">
        <p className="text-2xl font-handwritten text-center text-autorize-light-gray">Welcome to</p>
        <p className="text-brandSize -mt-2 mb-5 font-brand text-center bg-gradient-to-r from-autorize-light-gray to-autorize-dark-gray text-transparent bg-clip-text">AUTORIZE</p>
        {isLoading
          ? <img src={loading} alt="Loading" className="m-auto" width={30} height={30} />
          : <Button className="text-2xl font-handwritten text-center text-autorize-light-gray drop-shadow" variant="outlined" onClick={login}>
              Login to continue
            </Button>
        }
      </div>
    </div>
  )
}

export default Home
