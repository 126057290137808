import React, { useState } from "react"
import { useParams } from "react-router-dom"

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';

import { Alert } from "reactstrap"

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Header from "../components/Header";
import Loading from "../components/Loading";

import "../output.css";

import { startBudgetTx } from "../utils/authorizeCard"

// Autorize API runs on port 8444 (internally) - 2053 (externally)
// OCPP proxy ws server runs on port 8445 (internally) - 2096 (externally)
// This website runs on port 8443 (internally) - 443 (externally)

const ChargeWithBudget = () => {
  const {
    error: authError,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout,
  } = useAuth0()

  const { charger, connector } = useParams()

  const [authenticationError, setAuthenticationError] = useState(undefined)

  const [state, setState] = useState({
    //error: null,
    apiMessage: null, // null | { color: 'success' | 'warning', msg: string }
  })

  const [budget, setBudget] = useState(50) // default budget

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(
      )
    } catch (error) {
      setAuthenticationError(error)
      return undefined
    }
  }

  if (authError !== undefined || authenticationError !== undefined) {
    console.log("Authentication error: " + authError ?? authenticationError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }

  }

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }
  }

  const startSession = async () => {
    try {
      const token = await getAccessToken()
      if (token === undefined) return // failed to fetch access token
      
      // Now try to start the session
      console.log("Contacting charger...")
      setState({
        ...state,
        apiMessage: { color: 'blue', msg: 'Contacting charger...' }
      })

      const budgetInCents = budget * 100 // convert to cents
      await startBudgetTx(charger, connector, budgetInCents, token)

      console.log("Session started")
      setState({
        ...state,
        apiMessage: { color: 'green', msg: 'Session started. Your car should start charging soon.' }
      })
    } catch (error) {
      setState({
        ...state,
        apiMessage: { color: 'warning', msg: 'Failed to start session: ' + error.message }
      })
    }
  }

  const handle = (e, fn) => {
    e.preventDefault()
    fn()
  }

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({
      ...state,
      apiMessage: undefined,
    })
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <div className="bg-light-gray h-screen">
      {state.error === "consent_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleConsent)}
          >
            consent to get access to users api
          </a>
        </Alert>
      )}

      {state.error === "login_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            log in again
          </a>
        </Alert>
      )}

      <Snackbar
        autoHideDuration={6000}
        open={!!state.apiMessage}
        onClose={handleClose}
        message={state.apiMessage?.msg}
        color={state.apiMessage?.color}
        action={action}
      />

      <Header />
      <div className="flex flex-col items-center">
        <p className="text-xl font-bold mt-3 mb-2 text-center">Set a maximum budget for your session</p>
        <NumberInput
          aria-label="Maximum budget input"
          min={1}
          value={budget}
          onChange={(_event, val) => setBudget(val)}
        />
        <Button
          className=""
          onClick={startSession}>
          Start
        </Button>
      </div>
    </div>
  )
}

//export default ChargeWithBudget;

export default withAuthenticationRequired(ChargeWithBudget, {
  onRedirecting: () => <Loading />,
})
